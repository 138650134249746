@import "../../themes/generated/variables.base.scss";

.registrationInfo {
  width: 40%;
  padding: 5px 15px;
}

.item {
  padding: 0px 5px;
}

.title {
  color: darken($base-accent, 35);
  margin-top: 10px;
  padding-left: 10px;
  text-decoration: underline;
  
}

.caption {
  color: darken($base-accent, 25);
  padding-left: 50px;
  font-size: smaller;
}

.information {
  font-weight: bold;
  font-size: smaller;
}

.visitor {
  font-weight: bold;
  font-size: smaller;
  cursor: pointer;
}