@import "../../themes/generated/variables.base.scss";

.amountBox {
    background-color: rgba(95, 138, 175, 0.1);
    margin: 5px 0px 0px 10px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: $base-border-radius;
    position: relative;
    .caption {
        color: $base-text-color;
        font-size: 0.75rem;
        font-weight: bold;
        text-shadow: 0px 0px 6px rgba(26, 26, 26, 0.3);
        position: absolute;
        top: 0px;
        left: -40px;
    }
    .amount {
        color: $base-text-color;
        font-size: 1.2rem;
        font-weight:600;
        text-shadow: 0px 0px 6px rgba(26, 26, 26, 0.3);
        text-align: right;
        position: absolute;
        bottom: 0px;
        right: 10px;
    }
}