.rejectInfo p {
     text-align: center;
     font-size: larger;
     // font-weight: bold;
}

.homeInfo {
     font-weight: bold;
     margin: 0px;
     text-decoration: underline;
     letter-spacing: 1px;

}