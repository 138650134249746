.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.loginForm {
  h2 {
    text-align: center;
    margin: 0px 0px 15px;
    letter-spacing: 5px;
    padding: 0px;
  }
  h4 {
    text-align: center;
    margin: 10px 0px; 
    letter-spacing: 2px;
  }
  h6 {
    font-size: large;
    font-weight: normal;
    text-align: center;
    margin: 5px 0px; 
    letter-spacing: 1px;
  }
}