@import "../../themes/generated/variables.base.scss";

.visit-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    a {
      font-size: 14px;
      text-decoration: none;
    }
  }
  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}