@import "../../themes/generated/variables.base.scss";
@keyframes fadeIn {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

h4 {
  color: $base-text-color; 
  text-shadow: 0px 0px 6px rgba(26, 26, 26, 0.3);
}

img {
  height: 50px;
  // border-radius: 5px;
  // border: 1px solid gray;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
}

.titleBanner {
  font-size: 20px;
  position: absolute;
  top: 50px;
  left: 60px;
  background-color: rgba(253, 255, 255, 0.95);
  color: $base-text-color; 
  text-shadow: 0px 0px 6px rgba(26, 26, 26, 0.3);
  border-radius: 5px;
  border: 1px solid rgb(56, 56, 56);
  padding: 0px 25px;
  animation: 0.5s fadeIn;
}

.animateButtons {
  animation: 1.0s fadeInUp;
}


// 
.navBox {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 7px 10px;
  border-radius: 7px;
  overflow:hidden;
  
  right: 50px;
  z-index: 10;
  display: flex;

  .navButton {
    margin-left: 5px;
    opacity: 1;
  } 
  .navDateBox {
    display:inline-flex;
    margin-top: 1px;
    margin-left: 5px;
    height: 30px;
    opacity: 1;
  }
}


