@import "../../themes/generated/variables.base.scss";

.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

.amount {
  text-align: right;
}


.apiError {
  color: red;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
}

.balance {
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(128, 128, 128, 0.1);
  color: $base-text-color;
  border-radius: $base-border-radius;
  padding: 0px 35px;
  position: absolute;
  bottom: 5px;
  right: 40px;
  font-size: larger;
  font-weight: bold;
}

.datebox {
  height: 26px;
  width: 200px;
  margin-right: 5px;
}
