@import "../../themes/generated/variables.base.scss";

.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

.apiError {
  color: red;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
}

.summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.dateBox {
  position: absolute;
  display: flex;
  margin-top: 30px;

  .caption {
    top: -28px;
    left: 75px;
    position: absolute;
    font-size: larger;
  }

  .startDate {
    width: 150px;
    margin-right: 5px;
  }
  .endDate {
    width: 150px;
  }
}
